import React, { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import Charging from '../assets/loading.js'
import Footer from '../layouts/footer.js'





function Loading() {

  return (
      <>


        <Charging />

        <Footer />
      </>
  );
}

export default Loading;