import React, { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import SecondStep from '../assets/secondStep.js'
import Footer from '../layouts/footer.js'


function Billing() {

  return (
      <>

        <SecondStep />

        <Footer />
      </>
  );
}

export default Billing;