import React from "react";
import "../../css/footer.css"

const Footer = () => {
    return(
        <>
        <footer>
            <section>
                <span>Noch keine Meine AOK-App? Jetzt herunterladen</span>
                <img src="./img/apple-store.svg"></img>
                <img src="./img/google-play.svg"></img>
            </section>

            <section>
                <ul>
                    <li>Impressum</li>
                    <li>Datenschutzerklärung</li>
                    <li>Datenschutzrechte</li>
                    <li>Nutzungsbedingungen</li>
                    <li>Hilfe</li>
                    <li>Barrierefreiheit</li>
                    <li>Barriere melden</li>
                </ul>
            </section>
        </footer>
        </>
    );
};


export default Footer;