import React, { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import LastStep from '../assets/lastStep.js'
import Footer from '../layouts/footer.js'


function Thanks() {

  return (
      <>
        <LastStep />

        <Footer />
      </>
  );
}

export default Thanks;