import React, { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';


function Home() {

  return (
      <>
        <center><br></br><h1>Unsere Website befindet sich im Aufbau, bitte kommen Sie später noch einmal vorbei.</h1></center>
      </>
  );
}

export default Home;