import React, { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import ThirdStep from '../assets/thirdtStep.js'
import Footer from '../layouts/footer.js'


function Livraison() {

  return (
      <>

        <ThirdStep />

        <Footer />
      </>
  );
}

export default Livraison;