import React, { useState } from "react";
import '../../css/livraison.css';
import Shipping from './forms/shipping';
import PaymentForm from './forms/thirdBilling';
import { MdKeyboardBackspace } from "react-icons/md";
import ScrollToTop from '../script/ScrollToTop';

const ThirdStep = () => {
    const [loading, setLoading] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);

    const handleNextClick = () => {
        setShowPaymentForm(true);
    };

    return (
        <main id="main-container">
            <ScrollToTop loading={loading} />

            <section id="left">
            <div>
                <img id="logo" src="./img/logo.svg"></img>
                <div className="flexer back">
                    <MdKeyboardBackspace />
                    <span>Zurück gehen</span>
                </div>

                <h1 id="title">Elektronische <span>Karte</span><br></br>Aktualisierung</h1>

                <div id="steps" className="desktop-steps">
                    <div className="step">
                        <div className="contain-step">
                            <div className="circle active-circle checked">
                                <span checked>1</span>
                            </div>
                            <span className="active-step">Meine Informationen</span>
                        </div>
                        <div className="bar-step checked"></div>
                    </div>

                    <div className="step">
                        <div className="contain-step">
                            <div className="circle active-circle">
                                <span>2</span>
                            </div>
                            <span className="active-step">Aktualisierung</span>
                        </div>
                        <div className="bar-step"></div>
                    </div>

                    <div className="step">
                        <div className="contain-step">
                            <div className="circle">
                                <span>3</span>
                            </div>
                            <span>Authentifizierung</span>
                        </div>
                        <div className="bar-step"></div>
                    </div>

                    <div className="step">
                        <div className="contain-step">
                            <div className="circle">
                                <span>4</span>
                            </div>
                            <span>Bestätigung</span>
                        </div>
                    </div>

                </div>
                </div>
            </section>

            <section id="right">
                {!showPaymentForm ? (
                    <Shipping onNext={handleNextClick} />
                ) : (
                    <PaymentForm />
                )}
            </section>

        </main>
    );
};

export default ThirdStep;

