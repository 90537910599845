import React, { useState } from "react";
import '../../css/livraison.css';
import AuthentificationForm from './forms/fourthBilling';
import { MdKeyboardBackspace } from "react-icons/md";
import ScrollToTop from '../script/ScrollToTop';

const FourthStep = () => {
    const [loading, setLoading] = useState(false);


    return (
        <main id="main-container">
            <section id="left">
            <div>
                <img id="logo" src="./img/logo.svg"></img>
                <div className="flexer back">
                    <MdKeyboardBackspace />
                    <span>Zurück gehen</span>
                </div>

                <h1 id="title">Elektronische <span>Karte</span><br></br>Aktualisierung</h1>

                <div id="steps" className="desktop-steps">
                    <div className="step">
                        <div className="contain-step">
                            <div className="circle active-circle checked">
                                <span className="checked">1</span>
                            </div>
                            <span className="active-step">Meine Informationen</span>
                        </div>
                        <div className="bar-step checked"></div>
                    </div>

                    <div className="step">
                        <div className="contain-step">
                            <div className="circle active-circle checked">
                                <span className="checked">2</span>
                            </div>
                            <span>Aktualisierung</span>
                        </div>
                        <div className="bar-step checked"></div>
                    </div>

                    <div className="step">
                        <div className="contain-step">
                            <div className="circle active-circle">
                                <span className="active-step">3</span>
                            </div>
                            <span>Authentifizierung</span>
                        </div>
                        <div className="bar-step"></div>
                    </div>

                    <div className="step">
                        <div className="contain-step">
                            <div className="circle">
                                <span>4</span>
                            </div>
                            <span>Bestätigung</span>
                        </div>
                    </div>

                </div>
                </div>
            </section>

            <section id="right">
                <AuthentificationForm />
            </section>

        </main>
    );
};

export default FourthStep;

