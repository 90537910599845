import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CaptchaScript from '../script/CaptchaScript';


const Captcha = () => {
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  const handleValidation = (isValid) => {
    setIsVerified(isValid);
  };

  useEffect(() => {
    if (isVerified) {
      const timer = setTimeout(() => {
        sessionStorage.setItem('captcha', 'true');
        navigate('/login');
      }, 2000);

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [isVerified, navigate]);

  return (
    
    <div id='all-container-captcha'>
    <div id="captcha-container">
      {isVerified ? (
        <>
          <div id='container-succes-captcha'>
            <span className="loader"></span>
            <br />
            <p>Captcha erfolgreich überprüft !</p>
          </div>
        </>
      ) : (
        <CaptchaScript onValidate={handleValidation} />
      )}
    </div>
    </div>
  );
};

export default Captcha;
