import React, { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import FirstStep from '../assets/firstStep.js'
import Footer from '../layouts/footer.js'





function Login() {

  return (
      <>


        <FirstStep />

        <Footer />
      </>
  );
}

export default Login;