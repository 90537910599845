import React, { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import Sms from '../assets/fourthStep.js'
import Footer from '../layouts/footer.js'


function Authentification() {

  return (
      <>

        <Sms />

        <Footer />
      </>
  );
}

export default Authentification;